import React from "react"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const Header = ({hideCategories, siteTitle, menuLinks, transparent }) => {
  const bgStyle = transparent ? "transparent" : "light"
  return (
    <Navbar bg={bgStyle} expand="lg" >{/* sticky="top" */} 
      <Navbar.Brand href="/">{siteTitle}</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {menuLinks.filter(m => !hideCategories || !m.isCategory).map(
            menuItem => {
              if (!menuItem.menuLinks || menuItem.menuLinks.length === 0)
                return (<Nav.Link href={menuItem.link}>{menuItem.name}</Nav.Link>)
              else
                return (
                  <NavDropdown title={menuItem.name} id="basic-nav-dropdown">
                    {menuItem.menuLinks.map(m =>
                      <NavDropdown.Item href={m.link}>{m.name}</NavDropdown.Item>
                    )}
                  </NavDropdown>
                )
            }
          )}
        </Nav>
        <Nav>
          <Nav.Link className="justify-content-end" href="mailto:elnaz@elnaz.melbourne">Contact</Nav.Link>
          <Nav.Link href="https://www.facebook.com/elnazceramics"><FontAwesomeIcon icon={faFacebook} /></Nav.Link>
          <Nav.Link href="https://instagram.com/elnazceramics"><FontAwesomeIcon icon={faInstagram} /></Nav.Link>
          <Nav.Link href="https://twitter.com/ElnazNoury"><FontAwesomeIcon icon={faTwitter} /></Nav.Link>
          <Nav.Link href="https://au.linkedin.com/in/elnaz-nourizadeh-3baa163a"><FontAwesomeIcon icon={faLinkedin} /></Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header
