
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"

const Layout = ({hideCategories, transparentNav, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
            isCategory
          }
        }
      }
    }
  `)
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} menuLinks={data.site.siteMetadata.menuLinks} transparent={transparentNav} hideCategories={hideCategories}/>
      <div className="mx-auto px-3 pt-0">
        <main>{children}</main>
        <footer>
        </footer>
      </div>
      {/* <Footer transparent={transparentNav}/> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
